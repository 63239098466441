/* ------------------------------ SCHOOLS MODAL ------------------------------ */

.schools-modal .modal-dialog{ 
    margin: 0;
    bottom: 0;
    position: absolute;
    
  }
  
  .schools-modal .modal-content {
    border-radius: 0;
    height: 660px;
    overflow: scroll;
  }
  
  .schools-modal .modal-container {
   padding-top: 20px;
   width: 95%;
   padding-left: 30px;
  }

  @media (min-width: 576px) and (max-width: 660px) {
    .schools-modal .modal-content{ 
      width: 659px;
      padding-right: 13%;
    }
  }
  
  @media (min-width: 661px) and (max-width: 767px) {
    .schools-modal .modal-content{ 
      width: 766px;
      padding-right: 14%;
    }
  }