:root {
  --logo-color: #437e58;
  --logo-color2: #747b74;
  --logo-color-hover: #23c483;
  --white: #feffff;
  --bg-color-dark: #5d5e5e;
  --bg-color-mid: #eeeeee;
  --bg-color-light: #f8faf9;
  --black-a08: rgba(0, 0, 0, 0.08);
  --black-a30: rgba(0, 0, 0, 0.3);
  --color-shadow: var(--black-a30);
  --color-border-faint: var(--black-a08);
  --elevation-lined: 0px 0px 0px 1px var(--color-border-faint);
  --elevation-raised: var(--elevation-lined), 0px 4px 4px var(--color-shadow);
}

body,
html {
  background-color: var(--bg-color-light);
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1 {
  color: var(--logo-color2);
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 1px 3px 4px rgba(0, 0, 0, 0.015);
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  color: var(--logo-color2);
  font-size: 1.7rem;
  font-weight: 600;
  text-shadow: 1px 2px 2px rgba(30, 30, 30, 0.066);
  margin-bottom: 0.5em;
}

h3 {
  /* font-size: 20px; */
  color: var(--logo-color2);
  font-weight: 500;
  text-shadow: 1px 2px 2px rgba(30, 30, 30, 0.066);
  margin: 0.3em;
}

h4 {
  color: var(--bg-color-dark);
  margin-block: 2rem;
}
p {
  color: var(--bg-color-dark);
  font-weight: 300;
  margin: 0;
}

p strong {
  font-weight: 500;
}

p a {
  color: var(--logo-color);
  text-decoration: none;
}
small {
  color: #797979;
}

.app-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-top: 3.7rem;
  width: 100%;
}

/* ------------------------------ BUTTON ------------------------------ */
.button-class {
  padding: 0.8em 1.3em;
  color: #fff;
  background-color: rgb(101, 155, 120);
  box-shadow: rgba(67, 126, 88, 1);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.update-file-button {
  padding: 0.8em 1.3em;
  color: var(--logo-color);
  background-color: rgb(225, 255, 236);
  box-shadow: rgba(67, 126, 88, 1);
  border: none;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-bottom: 15px;
}


.button-class:hover {
  background-color: var(--logo-color);
  color: #fff;
}

button:active {
  transform: translateY(-1px);
}

/* ------------------------------ FORM ACCOUNT ------------------------------ */
.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  justify-content: center;
  text-align: center;
}

.form-account {
  width: 500px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px var(--bg-color-mid);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 5px;
  margin-bottom: 10px; /* add bottom margin */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
}

.form-account .button-class {
  margin: 0;
}

.text-muted {
  display: none;
}

.text-danger {
  font-size: 0.8rem;
  text-align: right;
}

@media (max-width: 800px) {
  .form-account {
    box-shadow: none;
    background-color: var(--bg-color-light);
    width: 400px;
  }
}
/* ------------------------------ ABOUT ------------------------------ */

.about{
  padding-top: 2rem;
  padding-inline: 15%;
  text-align: center;
}

.about h1 {
  margin-bottom: 3rem;
  font-size: 2.1rem;
}
.row-about {
  display: flex;
  justify-content: space-evenly;
  text-align: left;

  width: 100%;
  gap: 3rem;
  margin-bottom: 2.5rem;
}

.about-content {
  padding: 1rem;
}

.about-statistics{
  text-align: center;
  margin-bottom: 30px;
}

.about-us-text{
  font-size: 1.2rem;
}


.about-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}

.about-image-container {
  width: 30rem;
  height: 20rem
}

.about-main-image {
  height: 20rem;
  width: 30rem;
}

@media (max-width: 900px) {
  .about-main-image {
    width: 21rem;
    height: 21rem;
  }

  .row-about {
    flex-wrap: wrap;
    padding-inline: 4%;
  }

  .about-image-container {
    width: 21rem;
  }
  
}

@media only screen and (min-width: 768px) {
  .row-about {
    flex-direction: row;
    justify-content: space-between;
  }

  .row-about.reverse-order {
    flex-direction: row-reverse;
  }
}
/* ------------------------------ RESOURCES ------------------------------ */

.resources {
  text-align: center;
}

@media (max-width: 800px) {
  .resources {
    padding-top: 100px;
  }
}

/* ------------------------------ NAV ------------------------------ */
.nav {
  background: rgb(67, 126, 88);
  background: linear-gradient(
    126deg,
    rgba(67, 126, 88, 1) 25%,
    rgba(144, 222, 171, 1) 40%,
    rgba(67, 126, 88, 1) 70%
  );
  box-shadow: var(--elevation-raised);
}
.nav a {
  color: var(--bg-color-light);
}

.nav a:hover {
  color: var(--logo-color-hover);
}

.company-name {
  margin-left: 30px;
}


.dropdown-toggle::after {
  display: none;
}

.nav-icon {
  text-align: center;
}

.nav-link {
  text-align: center;
}

@media (max-width: 750px) {
  .company-name {
    display: none;
  }

  .nav-link {
    padding: 5px;
    padding-bottom: 20px;
    font-weight: 600;
  }

  .nav a:hover {
    color: white;
  }

  nav .navbar-collapse .dropdown-menu {
    display: none;
  }
}

/* ------------------------------ FORM ------------------------------ */

.form {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px var(--bg-color-mid);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px;
}

.form input {
  min-width: 300px;
}

@media (max-width: 800px) {
  .form {
    box-shadow: none;
    background-color: var(--bg-color-light);
  }
}

/* ------------------------------ LOGIN ------------------------------ */

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: 22%;
}

.row-gap {
  gap: 2rem;
}

.icon-container {
  height: 17rem; /* Remove quotes */
  width: 17rem; /* Remove quotes */
  background-color: #eeeeee; /* Correct: hash for colors */
  display: flex;
  border-radius: 5%; /* Remove quotes */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px 0px lightgray; /* Remove quotes */
  padding: 1rem; /* Remove quotes */
  cursor: pointer;

  transition: all 0.3s ease-in-out; /* Remove quotes */

  &:hover {
    background-color: #f0f0f0; /* Correct: hash for colors */
    box-shadow: 0 0 10px 0px rgb(197, 197, 197); /* Remove quotes */
  }
}

.icon-signup {
  height: 5rem;
  width: 5rem;
  margin-bottom: 1rem;
}

@media (min-width: 1108px) {
  .row-gap {
    gap: 12rem;
  }

}

@media (max-width: 800px) {
  .login {
    margin-inline: 5%;
  }
  .icon-container {
    height: 13rem;
    width: 13rem;
  }

  .icon-signup {
    display: none;
  }
}
/* ------------------------------ VIDEO CONTAINER ------------------------------ */

.video-container {
  display: flex;
  width: 100%;
  height: 320px;
  overflow: hidden;
  z-index: -1;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  position: absolute;
  left: 13%;
  top: 20%;
  border-radius: 6px;
  background: rgb(0, 0, 0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
  background: linear-gradient(
    126deg,
    rgba(67, 126, 88, 0.5) 25%,
    rgba(144, 222, 171, 0.5) 40%,
    rgba(67, 126, 88, 0.5) 70%
  );
  box-shadow: var(--elevation-raised);
  padding: 1rem;
}

.video-content h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 2px 2px #3a3a3a;
  font-size: 28px;
}

@media (max-width: 800px) {
  .video-content {
    left: 8%;
  }
}
/* ------------------------------ SCHOOLS ------------------------------ */

.schools-container {
  background-color: white;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 0px;
}

.responsive-stack {
  display: flex;
  flex-wrap: wrap;
}

.responsive-stack .btn {
  padding: 0.5rem 0.5rem; /* decrease button size */
  font-size: 0.8rem; /* decrease font size */
}

.list-group {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item.active {
  background-color: #23c48411;
  border-color: #437e582f;
  color: black;
}

.depth-listing {
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.logo-wrapper {
  width: 75px;
  height: 75px;
}

.logo-wrapper-depth {
  width: 100px;
  height: 100px;
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
}

.logo-wrapper-depth img {
  width: 100%;
  height: 100%
}

.hover {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .schools-container {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .schools-container {
    margin: 0;
  }
  .tab-content {
    display: none;
  }
}

/* ------------------------------ SCHOOLS CAROUSEL ------------------------------ */
.slider {
  margin-top: 80px;
  background: #fff; /* replace this with your own color */
  border-radius: 20px;
  height: 150px;
  overflow: hidden;
  position: relative;
  width: auto;
  margin-bottom: 25px;
}

.slider .slide-track {
  animation: scroll 200s linear infinite;
  display: flex;
  width: 2750px; /* replace calc with fixed value, adjust according to your needs */
}

.slider .slide {
  height: 150px;
  width: 100px;
  margin: 30px;
}

.slider .slide img {
  height: 50%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 11));
  }
}

@media (max-width: 800px) {
  .slider {
    display: none;
  }
}
/* ------------------------------ EVENTS ------------------------------ */

.carousel {
  background-color: var(--white);
  border-radius: 20px;
  overflow: hidden;
  height: 520px;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 40px;
  margin-bottom: 80px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.carousel-item img {
  height: 340px !important;
  width: 100%;

  object-fit: cover;
  object-position: top;
}

.carousel-content {
  padding: 20px;
}

/* ------------------------------ EXCLUSIVE CONTENT------------------------------ */

.exclusive-container {
  height: 520px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  margin-bottom: 80px;
  cursor: pointer;
}

.exclusive-text-container {
  margin-top: 20px;
}

.exclusive-image-container {
  background-color: var(--bg-color-mid);
  height: 340px;
  width: auto;
}

.exclusive-container img {
  margin-top: 8px;
  height: 95%;
  filter: drop-shadow(0px 5px 5px rgba(255, 255, 255, 0.3));
}

/* ------------------------------ BLOGS ------------------------------ */

.blogs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.card-wrapper {
  height: 18rem;
  width: 20rem;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  overflow: hidden;
  margin: 35px;
  margin-bottom: 25px;
}

.card-blog-image {
  height: 70%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.text-container {
  padding: 20px;
}

.text-container p {
  font-weight: 600;
}

@media (max-width: 1000px) {
  .card-wrapper {
    margin: 14px;
  }
}

/* ------------------------------ PROFILE INFO ------------------------------ */

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  justify-content: center;
  text-align: center;
}

.profile-row {
  justify-content: center;
}
.profile-container {
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  height: 37rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 2.5rem;
  overflow-y: auto;
  min-width: 18rem;
}

.profile-container-member {
  background-color: var(--white);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  height: 37rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  padding: 2.5rem;
  overflow-y: auto;
  min-width: 35rem;
}

.profile-container p {
  overflow-wrap: anywhere;
}

.image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: var(--bg-color-mid);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  position: relative;
}

.image-container img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.favorite {
  background-color: var(--bg-color-light);
  border: 1px solid var(--bg-color-mid);
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding: 10px;
  text-align: start;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.delete-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
/* ------------------------------ FOOTER ------------------------------ */

footer {
  margin-top: 30px;
  height: 120px;
  width: 100%;
  background-color: #eeeeee;
  color: rgb(121, 121, 121);
  display: grid;
  place-items: center;
  position: relative;
  bottom: 0;
}

.footer input {
  height: 35px;
}
.footer-container {
  width: 75%;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
}

.footer small {
  display: flex;
}

.footer a {
  color: var(--bg-color-dark);
}

.subscription-container {
  display: flex;
}

.subscription-container button {
  margin-top: 0px;
  padding: 9px;
  margin-left: 11px;
  font-size: 13px;
  font-weight: 700;
}

@media (max-width: 920px) {
  footer {
    height: 120px;
  }

  .footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .footer-subscribe {
    display: none;
  }

  .subscription-container {
    flex-wrap: wrap;
  }
}

/* ------------------------------ HEART-FAVORITES ------------------------------ */

.hover-heart {
  color: lightgray;
}

.hover-heart:hover {
  color: var(--logo-color);
}

.hover-heart.liked {
  color: var(--logo-color);
}

/* ------------------------------ SKELETONS ------------------------------ */

.skeleton {
  background-color: #ddd;
  border-radius: 4px;
  animation: skeleton-loading 1.2s infinite ease-in-out;
}

@keyframes skeleton-loading {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}

.skeleton-text {
  margin-top: 10px;
  height: 20px;
  width: 80%;
}

.skeleton-img {
  height: 60px;
  width: 100%;
  margin-bottom: 10px;
}
